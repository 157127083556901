<template>
  <div>
    <h5 class="text-uppercase text-dark">
      <b>
        <i v-b-toggle.collapse-info class="fas fa-info-circle fa-lg text-primary info-icon fa-fw" title="Show More Information" />
        <span v-if="fiscalYear < 34">Q4.</span> How have my school's average value-added growth scores changed over time?
      </b>
    </h5>

    <b-collapse id="collapse-info" class="mb-3">
      <b-card-header>
        <b-card-title v-b-toggle.collapse-info>
          <b-icon-x />
          Information
        </b-card-title>
      </b-card-header>
      <b-card class="mb-2">
        <b-card-text>
          <p class="text-secondary font-size-md font-weight-light">
            This school-level line chart displays your average value-added growth scores over a three-year period. The horizontal axis is the year. The vertical axis is average value-added growth score. State, district, and school values are provided on the same chart for context. Select subject, grade, and/or subgroup to get started.
          </p>

          <h5 class="text-uppercase text-dark mx-auto text-center">
            <b>Growth Over Time</b>
          </h5>
          <div class="row">
            <div class="col-md-5">
              <img src="/Content/growth/q4_chart.png" class="img-fluid" alt>
            </div>
            <div class="col-md-7">
              <div class="bg-light">
                <br>
                <h5 class="text-dark text-center text-uppercase">
                  Guiding Questions
                </h5>
                <ul class="text-secondary font-size-md font-weight-light">
                  <li>What trends over time are evident for your school compared to the district and state?</li>
                  <li>How do any trends compare among subjects, grade levels, and/or subgroups?</li>
                  <li>What additional evidence might help you understand the trends in student growth?</li>
                </ul>
              </div>
            </div>
          </div>
          <br>
          <br>

          <h5 class="text-uppercase text-dark mx-auto text-center">
            <b>Growth Over Time</b>
          </h5>
          <div class="row">
            <div class="col-md-7">
              <p class="text-secondary font-size-md font-weight-light">
                A student table is available for view and download into an Excel Workbook. The student table displays each students’ value-added growth scores and ACT Aspire scores for a three-year period.
              </p>
              <p class="text-secondary font-size-md font-weight-light">
                Student level scores should be interpreted in the presence of additional evidence about student performance. Three years of ACT Aspire scores may help explain students’ growth scores to an extent. Understanding a students’ learning from evidence at the local level is critical prior to planning next steps for an individual student.
              </p>
            </div>
            <div class="col-md-5">
              <img src="/Content/growth/q4_table.png" class="img-fluid" alt>
            </div>
          </div>

          <div class="col-md-12">
            <div class="bg-light">
              <br>
              <h5 class="text-dark text-center text-uppercase">
                Guiding Questions
              </h5>
              <ul class="text-secondary font-size-md font-weight-light">
                <li>What do the student growth scores, coupled with their achievement scores, tell you about their learning trajectories over the past three years?</li>
                <ul>
                  <li>What additional evidence might help you uncover more specific information about your students’ learning needs?</li>
                  <li>Are there patterns that you uncovered in grade level or subgroup growth that might be explored further as you look at the school, grade, subgroup, and student-level growth trajectories over time?</li>
                </ul>
              </ul>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'info-panel',
  data() {
    return {
    }
  },
  computed: {
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
}
</script>