<template>
  <div v-if="render">
    <highcharts class="chart" :options="chartOptions" style="page-break-inside: avoid; break-inside: avoid;" />
    <b-modal size="xl" :id="modalId">
      <template v-slot:modal-title>
        <h4>Details and Score Band {{ category }}</h4>
      </template>
      <raw-data-table :score="category" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { histogram } from '../helpers.js';
import { subjectCase } from '../helpers.js';
import RawDataTable from '../tables/RawDataTable.vue'

export default {
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    // excelExportData() {
    //   return {
    //     name: `F1-Distribution-Subject-${subjectCase(this.subject)}`,
    //     items: this.seriesData.map(row => {
    //       return {
    //         'Score Band': row.x,
    //         'Percent': row.y.toFixed(2) + '%',
    //         'Teachers': row.count
    //       }
    //     })
    //   }
    // },
    showDrilldownModal(category)
    {
      if (this.canViewTeacherData)
      {
        this.category = Number(category)
        this.$bvModal.show(this.modalId)
      }
    }
  },
  components: {
    RawDataTable
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubject', 'cutScores', 'canViewTeacherData']),
    ...mapState('globalModule', ['userContext']),
    canViewTeacherData() {
      return !(this.userContext.isRecruitment || this.userContext.isTeacherCenter || this.userContext.isSuperintendentCoop)
    },
    seriesData() {
      return histogram((this.detailsBySubject[undefined] || [])
        .map(x => x.valueAddedScore), 1)
    },
    chartOptions() {
      return {
        plotOptions: {
          column: {
            events: {
              click: ({ point }) => {
                this.showDrilldownModal(point.category)
              }
            }
          }
        },
        title: {
          text: `Distribution of Classroom Value-Added Growth Scores`
        },
        xAxis: [
          {
            title: {
              text: 'Classroom Value-Added Growth Score'
            },
            min: 38,
            max: 120,
            plotBands: [    
            {
              color: {
                linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
                stops: [[0, 'rgba(34, 167, 240, 0.5)'],[1, 'rgba(34, 167, 240, 0.1)']]
              },
              from: this.cutScores(this.subject, null).find(s => s.rating == "2").min,
              to: this.cutScores(this.subject, null).find(s => s.rating == "2").max,
              label: {
                text: 'High Impact',
                rotation: 90,
                textAlign: 'left',
                style: { color: '#000' }
              }
            }],
            plotLines:[{
              value: '80',
              width: '2',
              zIndex: 4
            }],
          }
        ],
        yAxis: {
          title: {
            text: 'Percent'
          }
        },
        credits: {
          text: ''
        },
        tooltip: {
          headerFormat: null,
          pointFormat: '{point.count} ({point.y:.2f}%)',
          
        },
        chart: {
          marginTop: 70,
          marginRight: 20,
          marginBottom: 70,
          marginLeft: 70
        },
        series: [{
          name: 'Percent',
          type: 'column',
          pointPadding: 0,
          groupPadding: 0,
          pointPlacement: 'between',
          data: this.seriesData,
          showInLegend: false,
        }]
      }
    },
    modalId() {
      return `Modal-F1-Distribution-Subject-${subjectCase(this.subject)}`
    }
  },
  data() {
    return {
      category: null,
      subjectCase
    }
  }
}

</script>

<style scoped>
  ::v-deep .highcharts-button {
    display: none;
  }

  .highcharts-reset-zoom {
    display: initial !important;
  }
</style>