<template>
  <div class="row no-gutters">
    <div class="col-md-2">
      <ul class="essa-stats">
        <li class="no-gutters">
          <label class="col-md-5">Grade Range:</label>
          <span class="col-md-4">{{ essaStats.gradeRange }}</span>
        </li>
        <li class="no-gutters">
          <label class="col-md-5">Grade Span:</label>
          <span class="col-md-4">{{ gradeSpan(essaStats.gradeSpan) }}</span>
        </li>
      </ul>
    </div>
    <div class="col-md-4" v-if="fiscalYear < 34">
      <ul class="essa-stats">
        <li class="no-gutters">
          <label class="col-md-6 text-right">Value-Added Growth Score:</label>
          <span class="col-md-6 text-right pl-3">{{ essaStats.valueAddedGrowth }}</span>
        </li>
        <li class="no-gutters">
          <label class="col-md-6 text-right">Weighted Achievement Score:</label>
          <span class="col-md-6 text-right pl-3">{{ essaStats.weightedAchievement }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../PageMixin'

export default {
  name: 'student-trend-lines',
  data() {
    return {}
  },
  components: {},
  mixins: [PageMixin],
  computed: {
    ...mapState('academicsModule/growth', [
      'tab',
      'grade',
      'subject',
      'subgroup',
      'schoolInfo'
    ]),
    ...mapState('academicsModule/essa', ['essaData']),
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    },
    essaStats() {
      var stats = {
        gradeRange: 'RANGE',
        gradeSpan: 'SPAN',
        weightedAchievement: 0.1,
        valueAddedGrowth: 0.2
      }

      if (this.essaData && this.essaData.length > 0) {
        stats.gradeRange = this.essaData[0].gradeRange
        stats.gradeSpan = this.essaData[0].gradeSpan
        stats.weightedAchievement = this.essaData[0].wgtPerfScore[2]
        stats.valueAddedGrowth = this.essaData[0].growthScore[2]
      }

      if (this.schoolInfo) {
        if (this.schoolInfo.gradeSpan === 1) {
          stats.gradeRange = 'K-5'
          stats.gradeSpan = 'ELEM'
        } else if (this.schoolInfo.gradeSpan === 2) {
          stats.gradeRange = '6-8'
          stats.gradeSpan = 'MS'
        } else if (this.schoolInfo.gradeSpan === 3) {
          stats.gradeRange = '9-12'
          stats.gradeSpan = 'HS'
        }
      }
      return stats
    }
  },
  methods: {
    gradeSpan(span) {
      if (span === 'HS') return 'High School'
      if (span === 'ELEM') return 'Elementary School'
      if (span === 'MS') return 'Middle School'
      return span
    },
    loadData() {
      if (this.selectedLea && this.selectedLea.number) {
        if (this.fiscalYear == 34) {
          this.$store
            .dispatch('academicsModule/growth/getSchoolInfo', {
              lea: this.selectedLea.number,
              fiscalYear: this.fiscalYear,
            })
        }
        else if (this.fiscalYear) {
          this.$store
            .dispatch('academicsModule/essa/getEssaAcademicsInformation', {
              lea: this.selectedLea.number,
              fy: this.fiscalYear,
              filters: null
            })
            .then(() => {
              return this.$store.dispatch(
                'globalModule/addDebugMessage',
                'Data loaded in watcher'
              )
            })
        }
      }
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        this.loadData()
      }
    },
    fiscalYear: {
      immediate: true,
      handler() {
        this.loadData()
      }
    }
  }
}
</script>