const subjects = [
  { text: '(Select a subject)', value: null },
  { text: 'ELA', value: 'ela' },
  { text: 'Mathematics', value: 'math' },
  { text: 'Science', value: 'science' },
  { text: 'Geometry', value: 'geometry' },
  { text: 'Algebra', value: 'algebra' },
  { text: 'Biology', value: 'biology' },
]

export {
  subjects as Subjects
}