<template>
  <div v-if="render">
    <a name="table-1" class="jumptarget" />
    <table id="subject-table" class="table table-striped table-bordered">
      <caption>Table 1: Mean Classroom Value-Added Growth Scores</caption>
      <thead>
        <tr>
          <th class="text-right">
            N
          </th>
          <th class="text-right">
            Mean
          </th>
          <th class="text-right">
            Std Dev
          </th>
          <th class="text-right">
            Minimum
          </th>
          <th class="text-right">
            Maximum
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in tableData" :key="row.subject">
          <td class="text-right">
            {{ row.n.toLocaleString("en-US") }}
          </td>
          <td class="text-right">
            {{ row.mean.toFixed(2) }}
          </td>
          <td class="text-right">
            {{ row.stdDev.toFixed(2) }}
          </td>
          <td class="text-right">
            {{ row.min.toFixed(2) }}
          </td>
          <td class="text-right">
            {{ row.max.toFixed(2) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStatsByKey, subjectCase } from '../helpers.js';

export default {
  name: 'mean-teacher-value-added-growth-scores-by-subject',
  data() {
    return {
      subjectCase
    }
  }, 
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    excelExportData() {
      return {
        name: 'T1-Mean-Subject',
        items: this.tableData.map(row => {
          return {
            'N': row.n.toLocaleString("en-US"),
            'Mean': row.mean.toFixed(2),
            'Std Dev': row.stdDev.toFixed(2),
            'Minimum': row.min.toFixed(2),
            'Maximum': row.max.toFixed(2)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubject']),
    tableData() {
      return getStatsByKey(this.detailsBySubject)
    }
  }
}
</script>