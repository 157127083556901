<template>
  <div class="growth-container">
    <excel-button :title="title" />
    <h1>Classroom Value-Added Growth Reports</h1>
    <div class="row mb-2">
      <div class="col-md-12">
        <h2>
          <lea-display :lea="selectedLea" />
        </h2>
        <school-essa-stats v-if="selectedLea.type == 1" />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
import { router } from '../../../routes'
import Question1Page from './Question1/Question1Page.vue'
import Question2Page from './Question2/Question2Page.vue'
import StudentDataPage from './StudentData/StudentDataPage.vue'
// import StudentTrendLinesPage from './StudentTrendLines/StudentTrendLinesPage.vue'
import SchoolEssaStats from './SchoolEssaStats'
import HomePage from './HomePage.vue'
import TvamPdfPage from '../tvamPdfPage.vue'

More(Highcharts)

router.addRoutes([
  { path: '/Academics/ClassroomGrowth/', component: HomePage },
  { path: '/Academics/ClassroomGrowth/question-1', component: Question1Page },
  { path: '/Academics/ClassroomGrowth/question-2', component: Question2Page },
  { path: '/Academics/ClassroomGrowth/studentdata', component: StudentDataPage },
  { path: '/Academics/ClassroomGrowth/tvampdf', component: TvamPdfPage },
  // { path: '/Academics/ClassroomGrowth/student-trend-lines', component: StudentTrendLinesPage }
])

export default {
  components: {
    SchoolEssaStats
  },
  computed: {
    title() {
      const paths = this.$route.path.split('/')
      return paths[paths.length - 1]
    },
    fiscalYear() {
      return this.$store.state.academicsModule.teacherGrowth.fiscalYear
    }
  },
  async mounted() {
    return await this.$store.dispatch('academicsModule/teacherGrowth/getLoadedYears')
    .then(() => {
        const year = this.$store.state.academicsModule.teacherGrowth.loadedyears
        this.$store.commit('academicsModule/teacherGrowth/setSelectedYear', year[0])
        this.$store.commit('academicsModule/essa/setFiscalYear', year[0])
      })
  },
  mixins: [PageMixin],
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        this.$store.commit("academicsModule/teacherGrowth/setGradesServed", this.selectedLea.gradesServed)
        this.$store.dispatch('academicsModule/teacherGrowth/getIsTeacher')
      }
    }
  }
}
</script>