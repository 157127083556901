<template>
  <div>
    <pdf-button :additional-query-string-params="queryParams" v-if="teacherVAMData" />
    <excel-button title="TeacherVAM" :skip-component-check="true" v-if="teacherVAMData" />
    <h1>Teacher Value-Added Measures</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    
    <!-- We include the pdf page here for excel exports.  We will pass down a flag to tell the components to not render for performance, but it will be in the component tree for excel export -->
    <pdf-page :render="false" />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import { router } from '../../../routes'
import { mapState } from 'vuex'

import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'

import Home from './pages/Home.vue'
import Subject from './pages/Subject.vue'
import LetterGrade from './pages/LetterGrade.vue'
import NBC from './pages/NBC.vue'
import Appendix from './pages/Appendix.vue'
import DataAndTrend from './pages/DataAndTrend.vue'
import PdfPage from './pages/PdfPage.vue'
import TvamPdfPage from '../tvamPdfPage.vue'

More(Highcharts)

router.addRoutes([
  { path: '/HCPT/TVAM', name: 'teacher-vam', redirect: '/HCPT/TVAM/home' },
  { path: '/HCPT/TVAM/home', name: 'teacher-vam-home', component: Home },
  { path: '/HCPT/TVAM/subject', name: 'teacher-vam-subject', component: Subject },
  { path: '/HCPT/TVAM/lettergrade', name: 'teacher-vam-lettergrade', component: LetterGrade },
  { path: '/HCPT/TVAM/nbc', name: 'teacher-vam-nbc', component: NBC },
  { path: '/HCPT/TVAM/appendix', name: 'teacher-vam-appendix', component: Appendix },
  { path: '/HCPT/TVAM/data', name: 'teacher-vam-data', component: DataAndTrend },
  { path: '/HCPT/TVAM/pdf', name: 'teacher-vam-pdf', component: PdfPage },
  { path: '/HCPT/TVAM/tvampdf', component: TvamPdfPage }
])

export default {
    name: 'teacher-vam-page',
    mixins: [PageMixin],
    data() {
      return {
        routeChanging: false
      }
    },
    components: {
      PdfPage
    },
    computed: {
      ...mapState('teacherVAMModule', ['fiscalYear', 'teacherVAMData', 'stateSummary']),
      queryParams() {
        return "&fy=" + this.fiscalYear
      }
    },
}

</script>

<style>
  caption {
    caption-side: top;
  }

  .figure-caption {
    border-radius: 3px;
    background-color: #59a5e3;
    color: #f2f8fc;
    font-weight: bold;
    font-size: 1.5em;
    padding: 0px 5px !important;
  }

  .figure {
    border: 1px solid #dee2e6;
    width: 100%
  }
</style>