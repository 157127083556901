<template>
  <div>
    <ul class="nav nav-pills d-block mb-3">
      <li class="mr-2">
        <a class="nav-link ml-1">
          <i class="fa-fw fas fa-filter" /> <span class="ml-1 font-weight-bold">Filter</span>
        </a>
        <ul class="nav nav-pills">
          <li class="nav-item ml-2">
            <div class="nav-link">
              Fiscal Year
              <b-form-select v-model="fiscalYear">
                <b-form-select-option v-for="year in loadedYears" :key="year" :value="year">
                  {{ year + 1990 }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2">
        <router-link class="nav-link" active-class="active" :to="{path: `/HCPT/TVAM/home`}">
          <img src="/Content/icons/guidance.png" class="svg-icon"> Home
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link class="nav-link" active-class="active" :to="{path: `/HCPT/TVAM/subject`}">
          <!-- <img src="/Content/icons/closure.png" class="svg-icon"> --> Summary
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link class="nav-link" active-class="active" :to="{path: `/HCPT/TVAM/lettergrade`}">
          <!-- <img src="/Content/icons/closure.png" class="svg-icon"> --> Letter Grade
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link class="nav-link" active-class="active" :to="{path: `/HCPT/TVAM/nbc`}">
          <!-- <img src="/Content/icons/closure.png" class="svg-icon"> --> National Board Certification
        </router-link>
      </li>
      <li class="nav-item ml-2" v-if="canViewTeacherData">
        <router-link class="nav-link" active-class="active" :to="{path: `/HCPT/TVAM/data`}">
          <!-- <img src="/Content/icons/closure.png" class="svg-icon"> --> Data &amp; Trend
        </router-link>
      </li>
    </ul>

    <ul class="nav nav-pills flex-column mt-1 mb-3">
      <li class="mr-2">
        <a class="nav-link ml-1">
          <span class="font-weight-bold">Resources</span>
        </a>
        <ul class="nav nav-pills d-block">
          <li class="nav-item ml-2">
            <router-link style="font-size: .9em" :to="{ path: '/HCPT/TVAM/tvampdf' }" active-class="active" class="nav-link">
              <i class="fas fa-fw fa-file-pdf mr-1" /> Guidance Document
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      fiscalYear: null,
      lea: null
    }
  },
  computed: {
    ...mapState('teacherVAMModule', ['loadedYears', 'teacherVAMData']),
    ...mapState('globalModule', ['userContext']),
    canViewTeacherData() {
      return !(this.userContext.isRecruitment || this.userContext.isTeacherCenter || this.userContext.isSuperintendentCoop)
    },
  },
  async mounted() {
    await this.$store.dispatch('teacherVAMModule/getFiscalYears')
    
    if (this.$route.query.fy) {
      // if we have a fy in the query string, use that one
      this.fiscalYear = this.$route.query.fy
    }
    else {
      // else just set to the latest one
      this.fiscalYear = this.loadedYears[0]
    }
  },
  methods: {
    async reloadData() {
      if (this.lea && this.fiscalYear)
      {
        await this.$store.dispatch('teacherVAMModule/getStateSummary', {fiscalYear: this.fiscalYear})
        await this.$store.dispatch('teacherVAMModule/getDistrictSummary', {fiscalYear: this.fiscalYear})
        await this.$store.dispatch('teacherVAMModule/getTeacherVAMData', {lea: this.lea, fiscalYear: this.fiscalYear})
      }
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newData) {
        // we are storing the selectedLea's number in lea so we can prevent reloading the data every time the route changes
        if (this.lea != newData.number)
        {
          this.lea = newData.number
          this.reloadData()
        }
      }
    },
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    }
  },
}
</script>
