<template>
  <div>
    <h5 class="text-uppercase text-dark">
      <b><i v-b-toggle.collapse-info class="fas fa-info-circle fa-lg text-primary info-icon fa-fw" title="Show More Information" /><span v-if="fiscalYear < 34">Q3.</span> Are students in each performance level growing as expected?</b>
    </h5>

    <b-collapse id="collapse-info" class="mb-3">
      <b-card-header>
        <b-card-title v-b-toggle.collapse-info>
          <b-icon-x />
          Information
        </b-card-title>
      </b-card-header>
      <b-card class="mb-2">
        <b-card-text>
          <p class="text-secondary font-size-md font-weight-light">
            This chart indicates the average value-added growth score for students in each ACT Aspire readiness level. The horizontal axis indicates each of the four readiness levels. The vertical axis indicates the standard scale for the value-added growth score. The horizontal reference line indicates the expected growth value. The bar height above or below the horizontal reference line indicates how much the average is above or below the expected growth line. Select subject, grade, and/or subgroup to get started.
          </p>
          <h5 class="text-uppercase mx-auto text-dark text-center mb-60">
            <b>School Average Value-Added Growth by Readiness Level</b>
          </h5>
          <br>
          <div class="row">
            <div class="col-md-3">
              <img src="/Content/growth/q3_chart.png" class="img-fluid img-thumbnail" alt>
            </div>
            <div class="col-md-9">
              <p class="text-secondary font-size-md font-weight-light">
                The confidence intervals for the average scores for each readiness level are provided for each bar. If the confidence intervals overlap, then the difference(s) in the average growth scores among readiness levels are not significant. If the confidence intervals do not overlap, then the averages are significantly different [1] .
              </p>
              <p class="text-secondary font-size-md font-weight-light">
                Click on each bar in the chart to dig deeper into the readiness level to understand the students’ scores contributing to the average for that readiness level. A histogram of the student distribution of student growth scores for each readiness level shows how the student growth scores for the selected readiness level are spread.
              </p>
              <small class="text-secondary font-weight-light">
                [1] To determine the effect size of the difference between readiness levels’ growth scores you can use the Summary table means and standard deviations.
              </small>
            </div>
          </div>
          <br>

          <div class="col-md-12 bg-light">
            <br>
            <h5 class="text-dark text-uppercase text-center">
              Guiding Questions
            </h5>
            <ul class="text-secondary font-size-md font-weight-light">
              <li>Which group of students are meeting/exceeding growth and which ones are not? Are there any readiness groups that have particularly high or low averages?</li>
            </ul>
            <br>
          </div>

          <br>
          <div class="row">
            <h5 class="text-uppercase text-dark mx-auto text-center">
              <b>Summary Table of School Growth Scores by Readiness Level</b>
            </h5>
            <br>
            <br>
            <div class="col-md-6">
              <img src="/Content/growth/q3_table.png" class="img-fluid img-thumbnail" alt>
            </div>
            <div class="col-md-6">
              <code>
                <small>
                  <b>Caution:</b> Use the summary tables to check the number of students in the group. Be cautious about inferences when N size is small.
                </small>
              </code>
            </div>
          </div>
          <br>
          <br>

          <div class="row">
            <h5 class="text-uppercase text-dark mx-auto text-center">
              <b>Distribution of Student Growth Scores by Readiness Level</b>
            </h5>
            <br>
            <br>
            <div class="col-md-6">
              <br>
              <br>
              <img src="/Content/growth/q3_drilldown_chart.png" class="img-fluid" alt>
              <br>
              <br>
              <p class="text-uppercase text-dark text-center">
                <b>Summary Table of Student Growth Scores by Readiness Level</b>
              </p>
              <img src="/Content/growth/q3_drilldown_table.png" class="img-fluid img-thumbnail" alt>
            </div>
            <div class="col-md-5 bg-light">
              <br>
              <ul class="text-secondary font-size-md font-weight-light">
                <h5 class="text-primary text-dark text-uppercase">
                  Guiding Questions
                </h5>
                <li>Are more students at this readiness level clustered at points above or below the expected growth score of zero?</li>
                <li>How does the spread of students for the selected readiness level compare to students at all readiness levels?</li>
                <ul class="text-secondary font-size-md font-weight-light">
                  <li>Are more students at a readiness level growing more than students at other readiness levels?</li>
                  <li>Are more students at a readiness level losing more ground than students at other readiness levels?</li>
                  <li>Where are the extreme growth scores for students? Are they low at a specific readiness level or high at a specific readiness level or do all levels have a similar spread to the extremes of the scale?</li>
                  <li>Consult the Student Table to learn more about students’ value-added growth scores within a readiness level.</li>
                </ul>
              </ul>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'info-panel',
  data() {
    return {
    }
  },
  computed: {
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
}
</script>