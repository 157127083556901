import throttle from 'lodash.throttle'
import ApiService from '../../services/ApiService'
import { groupBy } from '../../helpers/globalHelpers'
import { createGetTransaction } from '@/stores/VuexTransaction'

const groupByGrade = groupBy('testGrade')

export class Growth {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''

    this.createGetTransaction = createGetTransaction

    this.createGetTransaction('leaGrowthTrendPivot',
      'setLeaGrowthTrendPivot',
      'getLeaGrowthTrendPivot',
      (payload) => encodeURI(`${this._baseUrl}api/growth/${this.state.fiscalYear}/${payload.lea}/lea-growth-trend-pivot`)
    )
  }

  namespaced = true

  state = {
    tab: 1,
    subject: null,
    grade: null,
    grades: [],
    subgroup: 1,
    studentGrowthDetails: [],
    readinessLevelSummary: [],
    readinessLevelDetails: [],
    leaGrowthSummary: [],
    leaGrowthTrend: null,
    growthVsAchievement: [],
    loadedyears: [],
    fiscalYear: null,
    leaGrowthTrendPivot: [],
    schoolInfo: {}
  }

  mutations = {
    setTab(state, tab) {
      state.tab = tab
    },
    setSubject(state, subject) {
      state.subject = subject
    },
    setSubgroup(state, subgroup) {
      state.subgroup = subgroup
    },
    setGrade(state, grade) {
      state.grade = grade
    },
    setStudentGrowthDetails(state, studentGrowthDetails) {
      studentGrowthDetails = studentGrowthDetails.map(score => {
        return Object.assign({
          groupedScores: {
            'ela': {},
            'math': {},
            'science': {},
            'geometry': {},
            'algebra': {},
            'biology': {},
          }
        }, score)
      })
      // Make immutable for performance increase
      state.studentGrowthDetails = Object.freeze(studentGrowthDetails)
    },
    setReadinessLevelDetails(state, data) {
      state.readinessLevelDetails = data
    },
    setReadinessLevelSummary(state, data) {
      state.readinessLevelSummary = data
    },
    setLeaGrowthSummary(state, data) {
      state.leaGrowthSummary = data
    },
    setCutScores(state, data) {
      state.cutScores = data
    },
    setLeaGrowthTrend(state, data) {
      state.leaGrowthTrend = data
    },
    setGrowthVsAchievement(state, data) {
      state.growthVsAchievement = data
    },
    setGradesServed(state, data) {
      var minimum = 3
      var maximum = 10
      var grades = [{
        text: '(All Grades)',
        value: null
      }]

      if (data) // a range was supplied
      {
        var arr = data.split("-")
        var first = parseInt(arr[0]) || 0
        var last = parseInt(arr[1])
        minimum = first
        maximum = last

        if (minimum < 3) minimum = 3
        if (maximum > 10) maximum = 10
      }

      for (var g = minimum; g <= maximum; g++) {
        grades.push({
          text: `Grade ${g}`,
          value: String(g)
        })
      }

      state.grades = grades
      if (state.grade < minimum || state.grade > maximum) state.grade = null
    },
    setLoadedYears(state, data) {
      state.loadedyears = data
    },
    setSelectedYear(state, data) {
      state.fiscalYear = data
    },
    setSchoolInfo(state, data) {
      state.schoolInfo = data
    }
  }

  getters = {
    fiscalYear: (state) => {
      return state.fiscalYear
    },
    showFilter() {
      return true //state.tab > 1
    },
    fiscalYears: (state) => {
      return [state.fiscalYear - 2, state.fiscalYear - 1, state.fiscalYear]
    },
    schoolYears: (state) => {
      return [state.fiscalYear + 1988, state.fiscalYear + 1989, state.fiscalYear + 1990]
    },
    studentGrowthDetailsFlat: (state) => {
      const value = state.studentGrowthDetails.map(growth => {
        return {
          firstName: growth.firstName,
          lastName: growth.lastName,
          studentUniqueIdentifier: growth.studentUniqueIdentifier,
          currentGrade: growth.currentGrade,
          groupedScores: growth.groupedScores,
          [`${state.fiscalYear+1990}elaScaleScore`]: (growth.groupedScores['ela'][state.fiscalYear] || {}).scaledScore,
          [`${state.fiscalYear+1990-1}elaScaleScore`]: (growth.groupedScores['ela'][(state.fiscalYear - 1).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990-2}elaScaleScore`]: (growth.groupedScores['ela'][(state.fiscalYear - 2).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990}elaGrowthScore`]: (growth.groupedScores['ela'][state.fiscalYear] || {}).residual,
          [`${state.fiscalYear+1990-1}elaGrowthScore`]: (growth.groupedScores['ela'][(state.fiscalYear - 1).toString()] || {}).residual,
          [`${state.fiscalYear+1990-2}elaGrowthScore`]: (growth.groupedScores['ela'][(state.fiscalYear - 2).toString()] || {}).residual,

          [`${state.fiscalYear+1990}mathScaleScore`]: (growth.groupedScores['math'][state.fiscalYear] || {}).scaledScore,
          [`${state.fiscalYear+1990-1}mathScaleScore`]: (growth.groupedScores['math'][(state.fiscalYear - 1).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990-2}mathScaleScore`]: (growth.groupedScores['math'][(state.fiscalYear - 2).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990}mathGrowthScore`]: (growth.groupedScores['math'][state.fiscalYear] || {}).residual,
          [`${state.fiscalYear+1990-1}mathGrowthScore`]: (growth.groupedScores['math'][(state.fiscalYear - 1).toString()] || {}).residual,
          [`${state.fiscalYear+1990-2}mathGrowthScore`]: (growth.groupedScores['math'][(state.fiscalYear - 2).toString()] || {}).residual,

          [`${state.fiscalYear+1990}scienceScaleScore`]: (growth.groupedScores['science'][state.fiscalYear] || {}).scaledScore,
          [`${state.fiscalYear+1990-1}scienceScaleScore`]: (growth.groupedScores['science'][(state.fiscalYear - 1).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990-2}scienceScaleScore`]: (growth.groupedScores['science'][(state.fiscalYear - 2).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990}scienceGrowthScore`]: (growth.groupedScores['science'][state.fiscalYear] || {}).residual,
          [`${state.fiscalYear+1990-1}scienceGrowthScore`]: (growth.groupedScores['science'][(state.fiscalYear - 1).toString()] || {}).residual,
          [`${state.fiscalYear+1990-2}scienceGrowthScore`]: (growth.groupedScores['science'][(state.fiscalYear - 2).toString()] || {}).residual,

          [`${state.fiscalYear+1990}geometryScaleScore`]: (growth.groupedScores['geometry'][state.fiscalYear] || {}).scaledScore,
          [`${state.fiscalYear+1990-1}geometryScaleScore`]: (growth.groupedScores['geometry'][(state.fiscalYear - 1).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990-2}geometryScaleScore`]: (growth.groupedScores['geometry'][(state.fiscalYear - 2).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990}geometryGrowthScore`]: (growth.groupedScores['geometry'][state.fiscalYear] || {}).residual,
          [`${state.fiscalYear+1990-1}geometryGrowthScore`]: (growth.groupedScores['geometry'][(state.fiscalYear - 1).toString()] || {}).residual,
          [`${state.fiscalYear+1990-2}geometryGrowthScore`]: (growth.groupedScores['geometry'][(state.fiscalYear - 2).toString()] || {}).residual,

          [`${state.fiscalYear+1990}algebraScaleScore`]: (growth.groupedScores['algebra'][state.fiscalYear] || {}).scaledScore,
          [`${state.fiscalYear+1990-1}algebraScaleScore`]: (growth.groupedScores['algebra'][(state.fiscalYear - 1).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990-2}algebraScaleScore`]: (growth.groupedScores['algebra'][(state.fiscalYear - 2).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990}algebraGrowthScore`]: (growth.groupedScores['algebra'][state.fiscalYear] || {}).residual,
          [`${state.fiscalYear+1990-1}algebraGrowthScore`]: (growth.groupedScores['algebra'][(state.fiscalYear - 1).toString()] || {}).residual,
          [`${state.fiscalYear+1990-2}algebraGrowthScore`]: (growth.groupedScores['algebra'][(state.fiscalYear - 2).toString()] || {}).residual,
          
          [`${state.fiscalYear+1990}biologyScaleScore`]: (growth.groupedScores['biology'][state.fiscalYear] || {}).scaledScore,
          [`${state.fiscalYear+1990-1}biologyScaleScore`]: (growth.groupedScores['biology'][(state.fiscalYear - 1).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990-2}biologyScaleScore`]: (growth.groupedScores['biology'][(state.fiscalYear - 2).toString()] || {}).scaledScore,
          [`${state.fiscalYear+1990}biologyGrowthScore`]: (growth.groupedScores['biology'][state.fiscalYear] || {}).residual,
          [`${state.fiscalYear+1990-1}biologyGrowthScore`]: (growth.groupedScores['biology'][(state.fiscalYear - 1).toString()] || {}).residual,
          [`${state.fiscalYear+1990-2}biologyGrowthScore`]: (growth.groupedScores['biology'][(state.fiscalYear - 2).toString()] || {}).residual,
        }
      })
      return value
    },
    readinessSummary: (state) => {
      const value = state.readinessLevelSummary.map(readiness => {
        return {
          subject: readiness.subject,
          group: 'All Students',
          readinessLevel: readiness.readinessLevel,
          totalNumber: readiness.numberSampled,
          grade: readiness.grade,
          minGrowthScore: readiness["minGrowthScore"],
          maxGrowthScore: readiness["maxGrowthScore"],
          avgGrowthScore: readiness["avgGrowthScore"],
          growthStandardDeviation: readiness["standardDeviation"],
          lowerConfidenceLimit: readiness["lowerConfidenceLimit"],
          upperConfidenceLimit: readiness["upperConfidenceLimit"]
        }
      })
      return value
    },
    subjectName: (state) => {
      return state.subject ? state.subject.toUpperCase() : "All Subjects"
    },
    studentGrowthGroupByGrade: (state) => {
      return groupByGrade(state.studentGrowthDetails)
    }
  }

  actions = {
    setTab({
      commit
    }, tab) {
      return commit('setTab', tab)
    },
    getStudentGrowth: ({
      commit,
      state
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/growth/${state.fiscalYear}/${payload.lea}/studentGrowth`), {
        subject: state.subject,
        grade: state.grade,
        subgroup: [state.subgroup],
        readinessLevel: state.readinessLevel
      }).then(data => commit('setStudentGrowthDetails', data))
    },
    getStudentGrowthCurrent: ({
      commit,
      state
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/growth/${state.fiscalYear}/${payload.lea}/studentGrowthCurrent`), {
        subject: state.subject,
        grade: state.grade,
        subgroup: [state.subgroup]
      }).then(data => {
        commit('setStudentGrowthDetails', data.growth)
        commit('setCutScores', data.cutScores)
      })
    },
    getReadinessSummaryThrottled: throttle(function ({
      dispatch
    }) {
      dispatch('getReadinessSummary')
    }, 100, {
      leading: true,
      trailing: false
    }),
    // eslint-disable-next-line
    getReadinessSummary: ({
      commit,
      state,
      rootGetters
    }) => {
      const lea = rootGetters['globalModule/leaContext'].SelectedLea.number
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/growth/${state.fiscalYear}/${lea}/readiness/summary`), {
        subject: state.subject,
        grade: state.grade
      }).then(data => commit('setReadinessLevelSummary', data))
    },
    getReadinessDetails: ({
      commit,
      state
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/growth/${state.fiscalYear}/${payload.lea}/readiness/details`), {
        subject: payload.subject,
        grade: state.grade,
        readinessLevel: payload.readinessLevel
      }).then(data => commit('setReadinessLevelDetails', data))
    },
    getLeaGrowthSummary: ({
      commit,
      state
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/growth/${state.fiscalYear}/${payload.lea}/leaGrowthSummary`), {
        subject: state.subject,
        grade: state.grade,
        subgroup: [state.subgroup]
      }).then(data => commit('setLeaGrowthSummary', data))
    },
    getLeaGrowthTrend: ({
      commit,
      state
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/growth/${state.fiscalYear}/${payload.lea}/lea-growth-trend`), {
        subject: state.subject,
        grade: state.grade,
        subgroup: state.subgroup
      }).then(data => commit('setLeaGrowthTrend', data))
    },
    getGrowthVsAchievement: ({
      commit,
      state
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/growth/${state.fiscalYear}/${payload.lea}/growthVsAchievement`), {
        subgroup: state.subgroup,
        grade: state.grade,
        subject: state.subject
      }).then(data => commit('setGrowthVsAchievement', data))
    },
    getLoadedYears: ({
      commit      
    }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/growth/years`)).then(data => commit('setLoadedYears', data))
    },
    getSchoolInfo: ({
      commit,
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/growth/${payload.fiscalYear}/${payload.lea}/schoolInfo`))
        .then(data => commit('setSchoolInfo', data))
    },
  }
}