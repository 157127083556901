<template lang="html">
  <div class="mt-4">
    <loadable-chart-card :load-data="loadData" card-style="" card-class="p-1 inner-card justify-content-center">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts v-show="!isLoading" class="chart" :options="chartOptions" />
          <loading-spinner v-show="isLoading" />
        </div>
        <div v-show="!slotProps.chartMode" class="m-3">
          <chart-data-table :items="dataItems()" :title="getTitle()" />
        </div>
      </template>
    </loadable-chart-card>
    <div class="col-md-12 alert alert-info d-print-none" style="text-align: center;">
      <b>
        Chart Zooming: 
      </b>
      Select an area to zoom by clicking and dragging.
      <br> 
      Click "Reset zoom" to return to default view.
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LoadingSpinner from '../../../LoadingSpinner'
import LoadableChartCard from '../../../LoadableChartCard.vue'
import ChartDataTable from '../../../ChartDataTable.vue'

export default {
  components: {
    LoadingSpinner,
    LoadableChartCard,
    ChartDataTable
  },
  props: {
    subject: {
      type: String
    },
    distributionType: {
      type: String,
      default: 'grade'
    },
    distributionMode: {
      type: Boolean,
      default: false
    },
    teacherMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      loadData: Promise.resolve(true),
      chartOptions: {
        credits: '',
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          backgroundColor: 'rgba(255,255,255,0.002)',
          events: {
            load: function() {
              var chart = this,
                renderer = chart.renderer
                renderer.label('Higher Achievement<br />Lower Growth', chart.plotLeft, chart.plotTop)
                  .css({ color: '#434348' })
                  .add()

                renderer.label('Lower Achievement<br />Lower Growth', chart.plotLeft, chart.plotTop + chart.plotHeight - 35)
                  .css({ color: '#434348' })
                  .add()

                renderer.label('Higher Achievement<br />Higher Growth', chart.plotLeft + chart.plotWidth - 10, chart.plotTop)
                  .css({ color: '#434348', 'text-anchor': 'end' })
                  .add()

                renderer.label('Lower Achievement<br />Higher Growth', chart.plotLeft + chart.plotWidth - 10, chart.plotTop + chart.plotHeight - 35)
                  .css({ color: '#434348', 'text-anchor': 'end' })
                  .add()
            }
          },
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          min: -2,
          max: 2,
          title: {
            enabled: true,
            text: 'Growth Score'
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true,
          plotLines: [
            {
              color: 'blue',
              value: 0,
              width: 1,
              zIndex: 0,
            }
          ],
        },
        yAxis: {
          min: 400,
          max: 440,
          title: {
            text: 'Scale Score'
          },
          plotBands: [],
          gridLineWidth: 1
        },
        legend: {},
        plotOptions: {
          scatter: {
            marker: {
              radius: 4,
              symbol: 'square',
              lineWidth: 1,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)'
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            },
            tooltip: {
              pointFormat: '<strong>{point.fullName}</strong><br />{point.name} {point.x} Growth Score<br />{point.y} Scale Score'
            }
          }
        },
        series: [
          {
            name: '',
            data: []
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('academicsModule/teacherGrowth', ['grade', 'subgroup', 'studentGrowthDetails', 'cutScores', 'teacher', 'fiscalYear']),
    ...mapGetters('academicsModule/teacherGrowth', ['studentGrowthGroupByGrade', 'teacherName']),
    ...mapState('globalModule', ['userContext']),
    canViewStudentData() {
      return !(this.userContext.isRecruitment || this.userContext.isTeacherCenter || this.userContext.isSuperintendentCoop)
    },
    gradeMode() {
      return this.grade && this.distributionType == 'grade'
    }
  },
  methods: {
    getLabel(subject) {
      if (subject == 'ela') return 'ELA'
      if (subject == 'math') return 'Math'
      if (subject == 'science') return 'Science'
      if (subject == 'biology') return 'Biology'
      if (subject == 'algebra') return 'Algebra'
      if (subject == 'geometry') return 'Geometry'
    },
    getTitle() {
      if (!this.teacherMode) {
        return `Grade ${this.grade} ${this.getLabel(this.subject)} Growth Scores for LEA`
      }
      return `Grade ${this.grade} ${this.getLabel(this.subject)} Growth for ${this.teacherName}'s Class`
    },
    setCutScoreBands() {
      if (this.fiscalYear > 33) {
        this.chartOptions.yAxis.min = 1000
        this.chartOptions.yAxis.max = 1124
        this.chartOptions.yAxis.endOnTick = false
      }
      const cutScore = this.cutScores.find(c => this.subject.toUpperCase().indexOf(c.subject) > -1)
      // console.log(cutScore)
      
      if (cutScore) {
        this.chartOptions.chart.events.load = null
        this.chartOptions.xAxis.plotLines[0].color = 'transparent'
        this.chartOptions.yAxis.plotBands = [
          {
            color: {
              linearGradient: { x1: 1, y1: 0, x2: 1, y2: 1 },
              stops: [[0, 'rgba(217, 30, 24, 0.4)'],[1, 'rgba(217, 30, 24, 0.1)']]
            },
            from: cutScore.close,
            to: this.fiscalYear > 33 ? 1000 : 400,
            label: {
              text: this.fiscalYear > 33 ? 'Level 1' : 'In Need of Support',
              align: 'left',
              style: { color: '#fff', fontWeight: 'bold' }
            }
          },
          {
            color: {
              linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
              stops: [[0, 'rgba(245, 171, 53, 0.5)'],[1, 'rgba(245, 171, 53, 0.1)']]
            },
            from: cutScore.close,
            to: cutScore.ready,
            label: {
              text: this.fiscalYear > 33 ? 'Level 2' : 'Close',
              align: 'left',
              style: { color: '#fff', fontWeight: 'bold' }
            }
          },
          {
            color: {
              linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
              stops: [[0, 'rgba(46, 204, 113, 0.5)'], [1, 'rgba(46, 204, 113, 0.1)']]
            },
            from: cutScore.ready,
            to: cutScore.exceeds,
            label: {
              text: this.fiscalYear > 33 ? 'Level 3' : 'Ready',
              align: 'left',
              style: { color: '#fff', fontWeight: 'bold' }
            }
          },
          {
            color: {
              linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
              stops: [[0, 'rgba(34, 167, 240, 0.5)'],[1, 'rgba(34, 167, 240, 0.1)']]
            },
            from: cutScore.exceeds,
            to: this.fiscalYear > 33 ? 1124 : 440,
            label: {
              text: this.fiscalYear > 33 ? 'Level 4' : 'Exceeds',
              align: 'left',
              style: { color: '#fff', fontWeight: 'bold' }
            }
          }
        ]
      }
      console.log(this.chartOptions)
    },
    setTeacherScores() {
      var index = 0
      const selectedGrade = this.grade
      Object.keys(this.studentGrowthGroupByGrade).forEach(grade => {
        this.chartOptions.series.push({
          name: `${this.teacherName}'s Class`,
          color: selectedGrade == grade ? "#7cb5ec" :
            !this.grade ? 
            ["rgb(144,237,125)", "rgb(247,163,92)", "rgb(128,133,233)", "rgb(241,92,128)", "rgb(228,211,84)", "rgb(43,144,143)", "rgb(244,91,91)", "rgb(145,232,225)", "rgb(67,67,72)", "rgb(124,181,236)"][index]
            : ["rgba(144,237,125,0.6)", "rgba(247,163,92,0.6)", "rgba(128,133,233,0.6)", "rgba(241,92,128,0.6)", "rgba(228,211,84,0.6)", "rgba(43,144,143,0.6)", "rgba(244,91,91,0.6)", "rgba(145,232,225,0.6)", "rgba(67,67,72,0.6)", "rgba(124,181,236,0.6)"][index],
          data: this.studentGrowthGroupByGrade[grade]
          .filter(d => {
            if (d[`${this.subject}TeacherId`] == this.teacher) {
              return true
            }
            return false
          })
          .map(d => {
            var item = {
              x: d[`${this.subject}Residual`],
              y: d[`${this.subject}ScaledScore`],
              fullName: this.canViewStudentData ? `${d.firstName} ${d.lastName}` : null
            }
            return (item.x === undefined || item.y === undefined) ? {} : item
          }),
          marker: {
            symbol: 'square',
            lineColor: "#3333FF",
          },
          turboThreshold: 0
        })
        ++index
      })
    },
    setGradeDistributionScores() {
      var index = 0
      const selectedGrade = this.grade
      Object.keys(this.studentGrowthGroupByGrade).forEach(grade => {
        this.chartOptions.series.push({
          name: `Grade ${grade} - ${this.getLabel(this.subject)}`,
          color: selectedGrade == grade ? "#7cb5ec" :
            !this.grade ? 
            ["rgb(144,237,125)", "rgb(247,163,92)", "rgb(128,133,233)", "rgb(241,92,128)", "rgb(228,211,84)", "rgb(43,144,143)", "rgb(244,91,91)", "rgb(145,232,225)", "rgb(67,67,72)", "rgb(124,181,236)"][index]
            : ["rgba(144,237,125,0.6)", "rgba(247,163,92,0.6)", "rgba(128,133,233,0.6)", "rgba(241,92,128,0.6)", "rgba(228,211,84,0.6)", "rgba(43,144,143,0.6)", "rgba(244,91,91,0.6)", "rgba(145,232,225,0.6)", "rgba(67,67,72,0.6)", "rgba(124,181,236,0.6)"][index],
          data: this.studentGrowthGroupByGrade[grade].map(d => {
            var item = {
              x: d[`${this.subject}Residual`],
              y: d[`${this.subject}ScaledScore`]
            }
            if (d[`${this.subject}TeacherId`] == this.teacher) {
              item.marker = {
                symbol: 'square',
                fillColor: "#3333FF",
              }
              item.fullName = `${d.firstName} ${d.lastName}`
            }
            return (item.x === undefined || item.y === undefined) ? {} : item
          }),
          marker: {
            symbol: selectedGrade == grade ? 'triangle' : 'square',
            lineColor:  selectedGrade == grade ? "#3333FF" : "#FFFFFF",
            zIndex: selectedGrade == grade ? 10 : 1,
            fillColor: '#33ffb1'
          },
          turboThreshold: 0
        })
        ++index
      })
    },
    setChartData() {
      this.$set(this.chartOptions, 'title', { text: this.getTitle() })
      this.chartOptions.series = []
      this.setCutScoreBands()

      if (!this.teacherMode) {
        this.setGradeDistributionScores()
      }
      else {
        this.setTeacherScores()
      }
    },
    distributionTypeChanged() {
      this.isLoading = true
      this.$nextTick(function () {
        var update = function () { 
          this.setChartData(this.studentGrowthDetails) 
          this.isLoading = false
        }
        setTimeout(update.bind(this), 200)
      })
    },
    dataItems() {
      return this.chartOptions.series.flatMap(s => {
        return s.data.map(i => {
          if (i.fullName)
            return { GrowthScore: i.x, ScaledScore: i.y, FullName: i.fullName }

          return { GrowthScore: i.x, ScaledScore: i.y,  } 
        })
      })
    }
  },
  watch: {
    distributionType() {
      this.distributionTypeChanged()
    },
    studentGrowthDetails: {
      immediate: true,
      handler(details) {
        this.setChartData(details)
      }
    }
  }
}
</script>