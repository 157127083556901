<template>
  <div class="growth-container">
    <excel-button :title="title" />
    <h1>School Value-Added Growth Reports</h1>
    <div class="row mb-2">
      <div class="col-md-12">
        <h2>
          <lea-display :lea="selectedLea" />
        </h2>
        <school-essa-stats v-if="selectedLea.type == 1" />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
import { router } from '../../../routes'
import GrowthHomePage from './GrowthHomePage.vue'
import EssaPdfPage from './EssaPdf/EssaPdfPage.vue'
import Question1Page from './Question1/Question1Page.vue'
import Question2Page from './Question2/Question2Page.vue'
import Question3Page from './Question3/Question3Page.vue'
import Question4Page from './Question4/Question4Page.vue'
import SchoolGrowthPdfPage from './SchoolGrowthPdfPage.vue'
import StudentDataPage from './StudentData/StudentDataPage.vue'
import StudentTrendLinesPage from './StudentTrendLines/StudentTrendLinesPage.vue'
import SchoolEssaStats from './SchoolEssaStats'


More(Highcharts)

router.addRoutes([
  { path: '/Academics/Growth/', component: GrowthHomePage, name: 'growth-home' },
  { path: '/Academics/Growth/essa-pdf', component: EssaPdfPage, name: 'essa-pdf' },
  { path: '/Academics/Growth/question-1', component: Question1Page },
  { path: '/Academics/Growth/question-2', component: Question2Page },
  { path: '/Academics/Growth/question-3', component: Question3Page },
  { path: '/Academics/Growth/question-4', component: Question4Page },
  { path: '/Academics/Growth/studentdata', component: StudentDataPage },
  { path: '/Academics/Growth/student-trend-lines', component: StudentTrendLinesPage },
  { path: '/Academics/Growth/guidancePdf', component: SchoolGrowthPdfPage },
])

export default {
  name: 'growth-page',
  
  components: {
    SchoolEssaStats
  },
  computed: {
    title() {
      const paths = this.$route.path.split('/')
      return paths[paths.length - 1]
    },
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
  async mounted() {
    return await this.$store.dispatch('academicsModule/growth/getLoadedYears')
    .then(() => {
        const year = this.$store.state.academicsModule.growth.loadedyears
        this.$store.commit('academicsModule/growth/setSelectedYear', year[0])
        this.$store.commit('academicsModule/essa/setFiscalYear', year[0])
      })
  },
  mixins: [PageMixin],
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        this.$store.commit("academicsModule/growth/setGradesServed", this.selectedLea.gradesServed)
      }
    }
  }
}
</script>