<template lang="html">
  <div class="ml-3 mr-0 p-0">
    <h4>{{ this.subject.toUpperCase() }} GROWTH OVER TIME</h4>
    <loadable-chart-card :load-data="loadData" card-class="p-1 inner-card justify-content-center">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode" class="m-3">
          <chart-data-table :items="dataItems()" :title="exportTitle()" />
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { DemographicGroups } from '../FilterOptions'
import { GrowthChartMarkers } from './GrowthChartMarkers'
import LoadableChartCard from '../../../LoadableChartCard.vue'
import ChartDataTable from '../../../ChartDataTable.vue'

export default {
  name: 'lea-growth-trend-chart',
  components: {
    LoadableChartCard,
    ChartDataTable
  },
  data() {
    return {
      loadData: Promise.resolve(true),
      growthChartMarkers: GrowthChartMarkers,
      chartOptions: {
        credits: '',
        chart: {
          backgroundColor: 'rgba(255,255,255,0.002)'
        },
        title: {
          text: this.subject.toUpperCase()
        },
        subtitle: {
          text: ''
        },
        xAxis: {
        },
        yAxis: {
          title: {
            text: 'Growth Score'
          }
        },
        legend: {},
        plotOptions: {
          series: {
            marker: {
              radius: 6,
              lineWidth: 2
            }
          }
        },
        series: [
          {
            name: 'dummy'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('academicsModule/growth', ['leaGrowthTrend', 'grade', 'subgroup']),
    ...mapGetters('globalModule', ['leaDictionary']),
    chartTitle() {
      return 'Grade: ' + (this.grade ? `${this.grade}` : 'All') + ', Subgroup: ' + DemographicGroups[this.subgroup-1].text
    },
  },
  props: {
    subject: String,
  },
  methods: {
    dataItems() {
      return this.chartOptions.series.flatMap(s => {
        console.log(s)
        return s.data.map((d, index) => {
          return { LEA: s.name, Year: this.chartOptions.xAxis.categories[index], GrowthScore: d } 
        })
      })
    },
    exportTitle() {
      return `${this.chartOptions.title.text} Growth Over Time`
    }
  },
  watch: {
    leaGrowthTrend: {
      immediate: true,
      handler() {
        this.chartOptions.series = []
        for (var index in this.leaGrowthTrend) {
          var leaScore = this.leaGrowthTrend[index]
          var lea = this.leaDictionary[leaScore.lea]
          var subjectGroup = leaScore.groupedStatistics[this.subject]
          if (['biology', 'geometry', 'algebra'].includes(this.subject)) {
            this.chartOptions.xAxis.categories = Object.keys(subjectGroup).map(y => parseInt(y) + 1990).filter(y => y > 2023)
          } else {
            this.chartOptions.xAxis.categories = Object.keys(subjectGroup).map(y => parseInt(y) + 1990)
          }          
          var data = Object.assign({
            data: Object.keys(subjectGroup).map(fy => subjectGroup[fy].meanScore)
          }, this.growthChartMarkers[lea.type])
          data.name = `${leaScore.lea} - ${lea.name}`
          if (['biology', 'geometry', 'algebra'].includes(this.subject)) {
            // get the last this.chartOptions.xAxis.categories.length years
            data.data = data.data.slice(-this.chartOptions.xAxis.categories.length)
          }
          this.chartOptions.series.push(data)
        }
      }
    }
  }
}
</script>