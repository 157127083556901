<template>
  <div v-if="render">
    <table class="table table-striped table-bordered" style="width:100%">
      <caption>
        Table 9: Summary Statistics for Classroom Value-Added Growth Scores by Subject and Grade
      </caption>
      <thead>
        <tr>
          <th>LEA Level</th>
          <th>Number of Teachers</th>
          <th>Mean Teacher Value-Added Score (VAS)</th>
          <th>Standard Deviation of Teacher VAS</th>
          <th>Standard Error of Mean Teacher VAS</th>
          <th>Value at 1 Standard Deviation Below Mean Teacher VAS</th>
          <th>Lower Bound of 80 (based on 95% CI)</th>
          <th>Value at 1 Standard Deviation Above Mean Teacher VAS</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="subject in summaryBySubjectAndGrade">
          <tr v-for="grade in grades" :key="subject.subject + grade">
            <template v-if="!(grade == '3' && subject.subject == 'SCI')">
              <th>{{ subjectCase(subject.subject) }}</th>
              <th>{{ grade }}</th>
              <td class="text-right">
                {{ getRowValue(subject.subject, grade).n.toLocaleString("en-US") }}
              </td>
              <td class="text-right">
                {{ getRowValue(subject.subject, grade).mean.toFixed(2) }}
              </td>
              <td class="text-right">
                {{ getRowValue(subject.subject, grade).stdDev.toFixed(2) }}
              </td>
              <td class="text-right">
                {{ getRowValue(subject.subject, grade).stdErr.toFixed(2) }}
              </td>
              <td class="text-right">
                {{ getRowValue(subject.subject, grade).oneStdDevBelow.toFixed(2) }}
              </td>
              <td class="text-right">
                {{ getRowValue(subject.subject, grade).lowerBoundOf80.toFixed(2) }}
              </td>
              <td class="text-right">
                {{ getRowValue(subject.subject, grade).oneStdDevAbove.toFixed(2) }}
              </td>
            </template>
          </tr>          
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { subjectCase } from '../helpers.js'

export default {
  data() {
    return {
      subjectCase,
      grades: ['3', '4', '5', '6', '7', '8', '9', '10', 'All']
    }
  },
  props: {
    render: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getRowValue(subject, grade) {
      const key = `${subject}_${grade}`

      if (typeof this.getRowValue.cache === 'undefined') this.getRowValue.cache = new Map()
      if (this.getRowValue.cache.has(key)) return this.getRowValue.cache.get(key)
      else
      {
        const value = this.summaryBySubjectAndGrade.find(s => s.subject == subject).grades.find(g => g.grade == grade) || {
          n: 0,
          mean: 0,
          stdDev: 0,
          stdErr: 0,
          oneStdDevBelow: 0,
          lowerBoundOf80: 0,
          oneStdDevAbove: 0
        }
        this.getRowValue.cache.set(key, value)
        return value
      }      
    },
    excelExportData() {
      return {
        name: 'T9-Summary-Subject-Grade',
        items: this.summaryBySubjectAndGrade.map(subject => {
          return subject.grades.map(grade => {
            return {
              'Number of Teachers': grade.n,
              'Mean Teacher Value-Added Score (VAS)': grade.mean.toFixed(2),
              'Standard Deviation of Teacher VAS': grade.stdDev.toFixed(2),
              'Standard Error of Mean Teacher VAS': grade.stdErr.toFixed(2),
              'Value at 1 Standard Deviation Below Mean Teacher VAS': grade.oneStdDevBelow.toFixed(2),
              'Lower Bound of 80 (based on 95% CI)': grade.lowerBoundOf80.toFixed(2),
              'Value at 1 Standard Deviation Above Mean Teacher VAS': grade.oneStdDevAbove.toFixed(2)
            }
          })
        }).flat()
      }
    }
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['summaryBySubjectAndGrade']),
  }
}
</script>