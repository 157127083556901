<template>
  <div v-if="render">
    <table id="subject-table" class="table table-striped table-bordered" style="width:100%">
      <caption>Table 6: Mean Classroom Value-Added Growth Scores Across All Grades for NBC Status</caption>
      <thead>
        <tr>
          <th>NBC Status</th>
          <th class="text-right">
            Teacher Mean Value-Added Score
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>No</th>
          <td class="text-right">
            {{ getTableValue(false) }}
          </td>
        </tr>
        <tr>
          <th>Yes</th>
          <td class="text-right">
            {{ getTableValue(true) }}
          </td>
        </tr>        
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
    }
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getTableValue(certified) {
      const key = `${certified}`

      if (typeof this.getTableValue.cache === 'undefined') this.getTableValue.cache = new Map()
      if (this.getTableValue.cache.has(key)) return this.getTableValue.cache.get(key)
      else {
        const val = ((this.tableData[0].scores || [])
          .find(score => score.nbc == certified) || 0)
          .mean

        const value = val ? val.toFixed(2) : '--'
        this.getTableValue.cache.set(key, value)
        return value
      }
    },
    excelExportData() {
      return {
        name: 'T6-Mean-NBC',
        items: [
          {
            'NBC Status': 'No',
            'Teacher Mean Value-Added Score': this.getTableValue(false),
          },
          {
            'NBC Status': 'Yes',
            'Teacher Mean Value-Added Score': this.getTableValue(true),
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubject']),
    tableData() {
      const scores = this.detailsBySubject[undefined].reduce((acc, curr) => {
          acc[curr.isNBC] = acc[curr.isNBC] || []
          acc[curr.isNBC].push(curr.valueAddedScore)
          return acc
        }, {})

      return [{
        subject: undefined,
        scores: Object.keys(scores).map(scoreKey => { 
          return {
            nbc: scoreKey == 'true',
            mean: scores[scoreKey].reduce((acc, curr) => acc + curr) / scores[scoreKey].length
          }
        })
      }]
    }
  }
}
</script>