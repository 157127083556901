<template lang="html">
  <div v-if="showFilter" class="growth-filter py-2">
    <b-form inline>
      <b-form-group label="Subject">
        <b-form-select v-model="subject" :options="subjects" />
      </b-form-group> 
      <b-form-group label="Grade Level" class="mx-1">
        <b-form-select v-model="grade" :options="grades" />
      </b-form-group>
      <b-form-group label="Teacher" class="mx-1" :disabled="!teachersEnabled">
        <b-form-select v-model="teacher" :options="teachers" value-field="teacherId" text-field="teacherName" />
      </b-form-group>
    </b-form>
    <!-- <div>{{ subject }} / {{ grade }} / {{ teachers }}</div> -->
  </div>
</template>

<script>
import { Subjects } from './FilterOptions'
import { mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
    }
  },
  methods: {
    getTeachers() {
      if (this.teachersEnabled) {
        this.$store.commit('academicsModule/teacherGrowth/setTeacher', null)
        this.$store.dispatch('academicsModule/teacherGrowth/getTeachers')
        .then(() => {
          this.$store.commit('academicsModule/teacherGrowth/setTeacher', this.teachers.length == 2 ? this.teachers[1].teacherId : null)
        })
      }
      else this.$store.commit('academicsModule/teacherGrowth/setTeachers', [])
    },
  },
  watch: {
    grade() { this.getTeachers() },
    subject() { this.getTeachers() },
    selectedLea: {
      immediate: true,
      handler() { this.getTeachers() }
    },
    fiscalYear() { this.getTeachers() },
  },
  computed: {
    subjects() {
      if (this.fiscalYear < 34 || this.schoolInfo.gradeSpan == 1) {
        return Subjects.filter(s => s.value != 'geometry' && s.value != 'algebra' && s.value != 'biology')
      }
      return Subjects
    },
    ...mapGetters('academicsModule/teacherGrowth', ['showFilter']),
    ...mapState('academicsModule/teacherGrowth', ['grades', 'teachers', 'fiscalYear', 'schoolInfo']),
    subject: {
      set(subject) {
        this.$store.commit('academicsModule/teacherGrowth/setSubject', subject)
      },
      get() {
        return this.$store.state.academicsModule.teacherGrowth.subject
      }
    },
    teacher: {
      set(teacher) {
        this.$store.commit('academicsModule/teacherGrowth/setTeacher', teacher)
      },
      get() {
        return this.$store.state.academicsModule.teacherGrowth.teacher
      }
    }, 
    grade: {
      set(grade) {
        this.$store.commit('academicsModule/teacherGrowth/setGrade', grade)
      },
      get() {
        return this.$store.state.academicsModule.teacherGrowth.grade
      }
    },
    teachersEnabled() {
      return this.subject && this.grade
    }
  }
}
</script>

