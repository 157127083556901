const ReadinessColors = ['#79a6d2', '#8cd98c', '#ffb366', '#ff5050']
const ReadinessLevels = ['Exceeds', 'Ready',  'Close', 'In Need Of Support']
const ReadinessLevels24 = ['Level 4', 'Level 3', 'Level 2', 'Level 1']
const ReadinessLevelHash = {
  1: 'Exceeds',
  2: 'Ready',
  3: 'Close',
  4: 'In Need Of Support'
}
const ReadinessLevelHash24 = {
  1: 'Level 4',
  2: 'Level 3',
  3: 'Level 2',
  4: 'Level 1'
}
const ReadinessLevelByNameHash = {
  'Exceeds': 1,
  'Ready': 2,
  'Close': 3,
  'In Need Of Support': 4
}
const ReadinessColorsHash = {
  1: '#79a6d2', 
  2: '#8cd98c', 
  3: '#ffb366', 
  4: '#ff5050'
}

//const Subgroups = ['', 'All Students', 'African American','Hispanic/Latino','White','Econ. Disadv.','EL','SPED']

const ReadinessLevelFormatter = (level, fiscalYear) => 
{
  if (fiscalYear > 33) 
    return ReadinessLevels24[level-1]

  return ReadinessLevels[level-1]
}
export {ReadinessLevels, ReadinessLevels24, ReadinessColors, ReadinessLevelHash, ReadinessLevelHash24, ReadinessColorsHash, ReadinessLevelByNameHash, ReadinessLevelFormatter}