<template>
  <div v-if="render">
    <a name="table-6" class="jumptarget" />
    <table id="subject-table" class="table table-striped table-bordered" style="width:100%">
      <caption>Table 4: Mean Classroom Value-Added Growth Scores by Letter Grade</caption>
      <thead>
        <tr>
          <th>Letter Grade</th>
          <th class="text-right">
            Teacher Mean Value-Added Score
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="grade in letterGrades" :key="grade">
          <th>{{ grade }}</th>
          <td class="text-right">
            {{ getTableValue(grade) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      letterGrades: ['A', 'B', 'C', 'D', 'F']
    }
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getTableValue(grade) {
      const key = `${grade}`

      if (typeof this.getTableValue.cache === 'undefined')
        this.getTableValue.cache = new Map()
      if (this.getTableValue.cache.has(key)) 
        return this.getTableValue.cache.get(key)
      else {
        const val = ((this.tableData[0].scores || [])
          .find(score => score.grade == grade) || 0)
          .mean

        const value = val ? val.toFixed(2) : '--'
        this.getTableValue.cache.set(key, value)
        return value
      }
    },    
    excelExportData() {
      // items is an array of objects, each object has a 'Letter Grade' property
      // there are also properties for each subject
      return {
        name: 'T4-Mean-LetterGrade',
        items: this.letterGrades.map(grade => {
          return { 
            'Letter Grade': grade,
            'Teacher Mean Value-Added Score': this.getTableValue(grade)
          }
        })
      } 
    },
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubject']),
    tableData() {
      const scores = this.detailsBySubject[undefined].reduce((acc, curr) => {
          acc[curr.schoolLetterGrade] = acc[curr.schoolLetterGrade] || []
          acc[curr.schoolLetterGrade].push(curr.valueAddedScore)
          return acc
        }, {})

      console.log(scores)
      
      return [{
        subject: undefined,
        scores: Object.keys(scores).map(scoreKey => { 
          return {
            grade: scoreKey,
            mean: scores[scoreKey].reduce((acc, curr) => acc + curr) / scores[scoreKey].length
          }
        })
      }]
    }
  }
}
</script>