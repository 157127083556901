<template>
  <div v-if="render">
    <highcharts class="chart" :options="chartOptions" style="page-break-inside: avoid; break-inside: avoid;" />
  </div>
</template>

<script>
import { subjectCase } from '../helpers.js';
import { mapGetters } from 'vuex';

export default {
  props: {
    subject: {
      type: String,
      required: true
    },
    render: {
      type: Boolean,
      default: true
    },
    figure: {
      type: Number,
      required: true
    }
  },
  methods: {
    excelExportData() {
      return {
        name: `F5-Distribution-NBC`,
        items: this.nbcStatuses.map(nbc => {
          const data = this.boxSeriesData[this.nbcStatuses.indexOf(nbc)]
          return data ?  {
            'NBC Status': nbc,
            'Minimum': data.low,
            'Lower Quartile': data.q1,
            'Median': data.median,
            'Upper Quartile': data.q3,
            'Maximum': data.high,
            'Outliers': this.outliersData.filter(outlier => outlier[0] == this.nbcStatuses.indexOf(nbc)).map(outlier => outlier[1]).join(', ')
          } : {}
        })
      }
    },
    maxThing() {
      console.log(this.cutScores(this.subject, null))
      return this.cutScores(this.subject, null).find(s => s.rating == "2").max
    }
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubject', 'cutScores']),
    scoresData() {
      // filter by subject
      const scoresByGrade = Object.keys(this.detailsBySubject).filter(row => row == this.subject).map(subject =>
        // take the rows from this subject and accumulate an array of scores for each nbc status
        this.detailsBySubject[subject].reduce((acc, curr) => {
          acc[curr.isNBC] = acc[curr.isNBC] || []
          acc[curr.isNBC].push(curr.valueAddedScore)
          return acc
        }, {})
        )

      // get an array of arrays, each array containing the scores for a letter grade.
      return this.nbcStatuses.map((nbc, idx) => { return { x: idx, y: scoresByGrade[0][nbc == 'Yes'] ? scoresByGrade[0][nbc == 'Yes'] : [] }})
    },
    boxSeriesData() {
      return this.scoresData.map(point => {
        const scores = point.y
        if (scores.length > 0) 
        {
          scores.sort((a, b) => a - b)
          const lowerFence = scores[Math.floor(scores.length / 4)] - 1.5 * (scores[Math.floor(scores.length * 3 / 4)] - scores[Math.floor(scores.length / 4)])
          const low = scores.find(score => score >= lowerFence) || scores[0]
          const higherFence = scores[Math.floor(scores.length * 3 / 4)] + 1.5 * (scores[Math.floor(scores.length * 3 / 4)] - scores[Math.floor(scores.length / 4)])
          const high = scores.reverse().find(score => score <= higherFence) || scores[0]
          const q3 = scores[Math.floor(scores.length / 4)]
          const median = scores[Math.floor(scores.length / 2)]
          const q1 = scores[Math.floor(scores.length * 3 / 4)]
          return { low, q1, median, q3, high }
        }
        else return null
      })
    },
    outliersData() {
      return this.scoresData.reduce((acc, curr) => {
        const scores = curr.y.sort((a, b) => a - b)
        
        const lowerFence = scores[Math.floor(scores.length / 4)] - 1.5 * (scores[Math.floor(scores.length * 3 / 4)] - scores[Math.floor(scores.length / 4)])
        const higherFence = scores[Math.floor(scores.length * 3 / 4)] + 1.5 * (scores[Math.floor(scores.length * 3 / 4)] - scores[Math.floor(scores.length / 4)])

        scores.filter(score => score <= lowerFence || score >= higherFence).forEach(score => {
          acc.push([ curr.x, score ])
        })
        return acc
      }, [])
    },
    chartOptions() {
      return {
        chart: {
          height: 600,
          marginLeft: 70,
          marginTop: 20,
          marginRight: 20,
          marginBottom: 120
        },
        title: {
          //text: `Distribution of Classroom Value-Added Growth Scores by NBC status for ${subjectCase(this.subject)}`
          text: ''
        },
        xAxis: {
          categories: this.nbcStatuses,
          title: {
            text: 'NBC Status'
          }
        },
        yAxis: {
          title: {
            text: 'Teacher Value-Added Scaled Score'
          },
          plotBands: [
            {
              color: {
                linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
                stops: [[0, 'rgba(34, 167, 240, 0.5)'],[1, 'rgba(34, 167, 240, 0.1)']]
              },
              from: this.cutScores(this.subject, null).find(s => s.rating == "2").min,
              to: this.maxThing(),
              label: {
                text: 'High Impact',
                textAlign: 'left',
                style: { color: '#000' }
              }
          }],
          plotLines:[{
            value: '80',
            width: '2',
            zIndex: 4
          }],
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: 'Summary',
            data: this.boxSeriesData,
            type: 'boxplot',
            showInLegend: false,
            fillColor: '#CCCCCC',
          },
          {
            name: 'Outliers',
            data: this.outliersData,
            type: 'scatter',
            //marker is an empty circle
            marker: {
              symbol: 'circle',
              radius: 3,
              fillColor: '#FFFFFF',
                lineWidth: 2,
                lineColor: null
            },
            tooltip: {
              pointFormat: 'Score: {point.y}'
            }
          }
        ]
      }
    }
  },
  data() {
    return {
      subjectCase,
      nbcStatuses: ['No', 'Yes']
    }
  }
}

</script>