<template lang="html">
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" style="border: none" container-class="" card-class="card-body p-1 justify-content-center" card-style="">
    <div>
      <div class="row" v-if="selectedLea.type != LeaTypes.State && selectedLea.type != LeaTypes.Coop">
        <div class="col-12">
          <b-button-group size="sm" v-if="grade && grade.length > 0 && subgroup > 1">
            <b-button variant="primary" @click="setDistribution('grade')" :class="{ 'active': distributionType == 'grade' }">
              Grade Distribution
            </b-button>
            <b-button variant="primary" @click="setDistribution('subgroup')" :class="{ 'active': distributionType == 'subgroup' }">
              Subgroup Distribution
            </b-button>
          </b-button-group>
        </div>
      </div>
      <div class="row" v-if="selectedLea.type != LeaTypes.State && selectedLea.type != LeaTypes.Coop">
        <div class="col-6" v-if="grade || subgroup > 1">
          <div v-if="subject && subject.length > 0">
            <student-growth-chart :subject="subject" v-if="subject != 'elp'" />
            <div class="alert alert-warning" v-else>
              No Data available for Selected Options. Please select a different Grade Level or Subject.
            </div>
          </div>
          <div v-else>
            <student-growth-chart subject="ela" />
            <student-growth-chart subject="math" />
            <student-growth-chart subject="science" />
            <student-growth-chart subject="geometry" />
            <student-growth-chart subject="algebra" />
            <student-growth-chart subject="biology" />
          </div>
        </div>
        <div :class="distributionColumnClass">
          <div v-if="subject && subject.length > 0">
            <student-growth-chart :subject="subject" :distribution-mode="(grade && grade.length > 0) || subgroup > 1" :distribution-type="derivedDistributionType" v-if="subject != 'elp'" />
            <div class="alert alert-warning" v-else>
              No Data available for Selected Options. Please select a different Grade Level or Subject.
            </div>
          </div>
          <div v-else>
            <student-growth-chart subject="ela" :distribution-mode="true" :distribution-type="derivedDistributionType" />
            <student-growth-chart subject="math" :distribution-mode="true" :distribution-type="derivedDistributionType" />
            <student-growth-chart subject="science" :distribution-mode="true" :distribution-type="derivedDistributionType" />
            <student-growth-chart subject="biology" :distribution-mode="true" :distribution-type="derivedDistributionType" />
            <student-growth-chart subject="geometry" :distribution-mode="true" :distribution-type="derivedDistributionType" />
            <student-growth-chart subject="algebra" :distribution-mode="true" :distribution-type="derivedDistributionType" />
          </div>
        </div>
      </div>
    </div>
    <div class="pt-3">
      <h3>Student Growth Summary Table</h3>
    </div>
    <growth-summary-table />
  </loadable-chart-card>
</template>

<script>
import { mapState } from 'vuex'
import { LeaTypes } from '../../../../helpers/leaTypes'
import LoadableChartCard from '../../../LoadableChartCard.vue'
import StudentGrowthChart from './StudentGrowthChart.vue'
import GrowthSummaryTable from './GrowthSummaryTable.vue'

export default {
  name: 'student-growth',
  data() {
    return {
      distributionType: 'grade',
      LeaTypes: LeaTypes
    }
  },
  props: {
    loadData: {
      type: Promise
    }
  },
  computed: {
    ...mapState('academicsModule/growth', ['grade', 'subgroup', 'subject']),
    distributionColumnClass() {
      return (this.grade && this.grade.length > 0) || this.subgroup > 1 ? 'col-6' : 'col-12'
    },
    derivedDistributionType() {
      if (this.grade && this.grade.length > 0 && this.subgroup > 1) {
        return this.distributionType
      }
      else if (this.subgroup > 1) return 'subgroup'
      else return 'grade'
    }
  },
  components: {
    LoadableChartCard,
    StudentGrowthChart,
    GrowthSummaryTable
  },
  methods: {
    setDistribution(type) {
      this.distributionType = type
    }
  }
}
</script>