<template lang="html">
  <div>
    <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="">
      <table-control-widget :fields="studentColumns" :per-page="tableOptions.perPage" @filter-changed="tableOptions.filter=$event" @page-length-changed="tableOptions.perPage=$event" :current-page="tableOptions.currentPage" :filter="tableOptions.filter" :busy="tableOptions.isLoading" :items="studentGrowthDetailsFlat" @filtered="onFiltered" />
      <b-table :ref="subject" :fields="studentColumns" :filter-included-fields="tableOptions.searchColumns" :per-page="tableOptions.perPage" :current-page="tableOptions.currentPage" :filter="tableOptions.filter" :busy="tableOptions.isLoading" :items="studentGrowthDetailsFlat" primary-key="studentUniqueIdentifier" @filtered="onFiltered">
        <template v-slot:head()="data">
          <span v-html="data.label" />
        </template>
        <template v-slot:cell(ela_growth_trend)="data">
          <trended-scores :scores="data.item.groupedScores.ela" value-property="residual" :show-sign-indicator="true" />
        </template>
        <template v-slot:cell(ela_scale_score_trend)="data">
          <trended-scores :scores="data.item.groupedScores.ela" value-property="scaledScore" />
        </template>
        <template v-slot:cell(math_growth_trend)="data">
          <trended-scores :scores="data.item.groupedScores.math" value-property="residual" :show-sign-indicator="true" />
        </template>
        <template v-slot:cell(math_scale_score_trend)="data">
          <trended-scores :scores="data.item.groupedScores.math" value-property="scaledScore" />
        </template>
        <template v-slot:cell(science_growth_trend)="data">
          <trended-scores :scores="data.item.groupedScores.science" value-property="residual" :show-sign-indicator="true" />
        </template>
        <template v-slot:cell(science_scale_score_trend)="data">
          <trended-scores :scores="data.item.groupedScores.science" value-property="scaledScore" />
        </template>
        <template v-slot:cell(biology_scale_score_trend)="data">
          <trended-scores :scores="data.item.groupedScores.biology" value-property="scaledScore" />
        </template>
        <template v-slot:cell(geometry_scale_score_trend)="data">
          <trended-scores :scores="data.item.groupedScores.geometry" value-property="scaledScore" />
        </template>
        <template v-slot:cell(algebra_scale_score_trend)="data">
          <trended-scores :scores="data.item.groupedScores.algebra" value-property="scaledScore" />
        </template>
        <template v-for="growthColumn in growthScoreColumnNames" v-slot:[`cell(${growthColumn})`]="data">
          <span :key="growthColumn">
            {{ data.value }} 
            <sign-indicator :value="data.value" />
          </span>
        </template>
      </b-table>
      <table-pagination-widget :total-rows-count="totalRowCount" :filter="tableOptions.filter" :filtered-rows-count="tableOptions.filteredRowCount" :page-length="tableOptions.perPage" @page-changed="tableOptions.currentPage=$event" />
    </loadable-chart-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { TableMixin } from '../../TableMixin'
import { ChartMixin } from '../../ChartMixin'
import TrendedScores from './TrendedScores.vue'
import SignIndicator from '../../SignIndicator'

const subjectLabels = ['ELA', 'Math', 'Science', 'Biology', 'Geometry', 'Algebra']

export default {
  name: 'student-table',
  mixins: [TableMixin, ChartMixin],
  components: {
    TrendedScores,
    SignIndicator
  },
  props: {
    subject: {
      type: String
    },
    showTrends: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      subjectLabels: subjectLabels,
      tableOptions: {
        isLoading: false,
        perPage: this.$printMode ? Number.MAX_SAFE_INTEGER : this.perPage,
        currentPage: 1,
        filteredRowCount: null,
        filter: null,
        searchColumns: [
          'studentUniqueIdentifier', 
          'firstName',
          'lastName'
        ],
        fields: [
          {
            label: 'UID',
            key: 'studentUniqueIdentifier',
            sortable: true
          },
          {
            key: 'firstName',
            label: 'FirstName',
            sortable: true
          },
          {
            key: 'lastName',
            label: 'LastName',
            sortable: true
          },
          {
            label: 'Grade',
            key: 'currentGrade',
            sortable: true
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('academicsModule/growth', [
      'studentGrowthDetailsFlat',
      'fiscalYears',
      'schoolYears',
    ]),
    ...mapState('academicsModule/growth', [
      'fiscalYear',
      'schoolInfo'
    ]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    trimmedYears() {
      return this.schoolYears.filter(y => y != 2020)
    },
    studentColumns() {
      var fields = this.tableOptions.fields.slice(0)

      if (this.showTrends) {
        fields = fields.filter(s => s.key != 'studentUniqueIdentifier')
        fields = fields.filter(s => s.key != 'currentGrade')
      }

      if (this.subject && this.subject.length > 0) {
        this.trimmedYears.forEach(schoolYear => {
          fields.push({
            label: `${schoolYear}<br />Growth Score`,
            key: `${schoolYear}${this.subject}GrowthScore`,
            sortable: true
          })
        })
        if (this.showTrends) {
          fields.push({
            key: `${this.subject}_growth_trend`,
            label: 'Growth<br />Trend'
          })
        }
        this.trimmedYears.forEach(schoolYear => {
          fields.push({
            label: `${schoolYear}<br />Scale Score`,
            key: `${schoolYear}${this.subject}ScaleScore`,
            sortable: true
          })
        })
        if (this.showTrends) {
          fields.push({
            key: `${this.subject}_scale_score_trend`,
            label: 'Scale<br />Score<br />Trend'
          })
        }
      } else {
        let filteredLabels = subjectLabels;
        if (this.fiscalYear < 34 || ((this.schoolInfo || {}) || {}).gradeSpan == 1) {
          filteredLabels = filteredLabels.filter(s => s != 'Geometry' && s != 'Algebra' && s != 'Biology')
        }
        filteredLabels.forEach(s => {
            fields.push({
              key: `${s.toLowerCase()}_growth_trend`,
              label: `${s}<br />Growth<br />Trend`
            })
            fields.push({
              key: `${s.toLowerCase()}_scale_score_trend`,
              label: `${s}<br />Scale<br />Score<br />Trend`
            })
          })
      }
      return fields
    },
    totalRowCount() {
      return this.studentGrowthDetailsFlat.length
    },
    growthScoreColumnNames() {
      return this.schoolYears.map(sy => `${sy}${this.subject}GrowthScore`)
    }
  },
  watch: {
    studentGrowthDetailsFlat() {
      this.$emit('bv::refresh::table', this.subject)
    }
  },
  methods: {
    nameFormatter(value, key, item) {
      return `${item.lastName}, ${item.firstName}`
    },
    onFiltered(items, count) {
      this.$set(this.tableOptions,'filteredRowCount', count)
      this.$set(this.tableOptions,'currentPage', 1)
    },
    goToLea(leaNumber) {
      const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
      window.scrollTo(0, 0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    excelExportData() {
      //get list of keys used in the table
      const columns = [...this.tableOptions.fields]
      const subjects = this.subject ? [this.subject] : this.subjectLabels
      
      if (this.studentColumns.some(c => !c.key.includes('trend'))) {
        subjects.forEach(subject => {
          this.trimmedYears.forEach(schoolYear => {
            columns.push({
              label: `${schoolYear} ${subject.toUpperCase()} Scale Score`,
              key: `${schoolYear}${subject.toLowerCase()}ScaleScore`,
            })
            columns.push({
              label: `${schoolYear} ${subject.toUpperCase()} Growth Score`,
              key: `${schoolYear}${subject.toLowerCase()}GrowthScore`,
            })
          })
        })
      }
      return {
        name: `Student Growth - ${(this.subject || 'All Subjects').toUpperCase()}`,
        items: this.studentGrowthDetailsFlat
          .map((s) => {
            var item = {}
            columns.forEach((col) => {

              item[(col.label || col.key).replace('<br />', ' ')] = s[col.key]
            })
            return item
          })
      }
    }
  }
}
</script>