<template>
  <div>
    <h5 class="text-uppercase text-dark">
      <b>
        <i v-b-toggle.collapse-info class="fas fa-info-circle fa-lg fa-fw text-primary info-icon" title="Show More Information" />
        <span v-if="fiscalYear < 34">Q2.</span> Are my students growing as much as they were expected to grow and how does this relate to their achievement?
      </b>
    </h5>

    <b-collapse id="collapse-info" class="mb-3">
      <b-card-header>
        <b-card-title v-b-toggle.collapse-info>
          <b-icon-x />
          Information
        </b-card-title>
      </b-card-header>
      <b-card class="mb-2">
        <b-card-text>
          <p class="text-secondary font-size-md font-weight-light">
            This student-level scatterplot displays your students’ value-added growth score and ACT Aspire scale score plotted together. The horizontal axis is the value-added growth score using a standard scale. The vertical axis is the ACT Aspire scale. You can explore this scatterplot by hovering over a student to get the achievement and growth score for the student. Select subject, grade, and/or subgroup to get started.
          </p>

          <h5 class="text-uppercase text-dark mx-auto text-center">
            <b>2019 Residual Scatter Plot Model (Value-Added Growth Score) by 2019 Achievement Scale Score</b>
          </h5>
          <div class="row">
            <div class="col-md-4">
              <img src="/Content/growth/q2_chart.png" class="img-fluid" alt>
            </div>
            <div class="col-md-8">
              <div class="bg-light">
                <br>
                <h5 class="text-dark text-center text-uppercase">
                  Guiding Questions
                </h5>
                <ul class="text-secondary font-size-md font-weight-light">
                  <li>What does the shape of the scatterplot tell you about growth and achievement for your students?</li>
                  <li>Are there any patterns in the achievement of students meeting or not meeting growth? Do you have students whose scores are extremely high or low in growth or achievement that may have impacted your school’s average scores?</li>
                  <li>How does the relationship between student growth and achievement differ</li>
                  <ul>
                    <li>by grade level?</li>
                    <li>by subgroup?</li>
                    <li>by grade level and subgroup?</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <br>
          <br>

          <h5 class="text-uppercase text-dark mx-auto text-center">
            What is the average growth of students by subject, grade, subgroup, or cross-tabulated by grade and subgroup?
          </h5>
          <div class="row">
            <div class="col-md-6">
              <img src="/Content/growth/q2_table.png" class="img-fluid" alt>
              <code>
                <small>
                  <b>Caution:</b> Use the summary tables to check the number of students in the group. Be cautious about inferences when N size is small.
                </small>
              </code>
            </div>
            <div class="col-md-6">
              <p class="text-secondary font-size-md font-weight-light">
                The Summary Table provides averages by subject, grade level, subgroup, and cross-tabulations between grade level
                and subgroup. The summary information—the number of students, the averages, the standard deviations, maximums, and
                minimums—are provided to guide you in interpreting the differences you might find among grade levels, subgroups,
                and cross-tabulations. Select subject, grade, and/or subgroup to get started.
              </p>
            </div>
          </div>
          <br>

          <div class="col-md-12">
            <div class="bg-light">
              <br>
              <h5 class="text-dark text-center mx-auto text-uppercase">
                Guiding Questions
              </h5>
              <ul class="text-secondary font-size-md font-weight-light">
                <li>In what way do the averages differ among GRADE LEVELS within each subject?</li>
                <ul>
                  <li>Are those differences large based on the standard deviation or less than 0.20 standard deviations?</li>
                  <li>Do any GRADE LEVELS have outlier student scores that may have impacted the average score?</li>
                  <ul>
                    <li>If so, do you think the grade level differences be comparable if you removed extreme scores?</li>
                  </ul>
                </ul>
                <li>In what way do the means differ among SUBGROUPS within each subject?</li>
                <ul>
                  <li>Are those differences large based on the standard deviation or less than 0.20 standard deviations? Are the means based on a large enough number of students to support SUBGROUP inferences?</li>
                  <li>Do any SUBGROUPS have outlier student scores that may have impacted the average score?</li>
                  <ul>
                    <li>If so, do you think the grade level differences be comparable if you removed extreme scores?</li>
                  </ul>
                </ul>
              </ul>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'info-panel',
  data() {
    return {
    }
  },
  computed: {
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
}
</script>