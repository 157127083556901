const ReadinessColors = ['#ff5050', '#ffb366', '#8cd98c', '#79a6d2']
const ReadinessLevels = ['In Need of Support', 'Close', 'Ready', 'Exceeds']
const ReadinessLevels24 = ['Level 1', 'Level 2', 'Level 3', 'Level 4']
//const Subgroups = ['', 'All Students', 'African American','Hispanic/Latino','White','Econ. Disadv.','EL','SPED']

const ReadinessLevelFormatter = (level) => ReadinessLevels[level]

const ReadinessLevelHash24 = {
  4: 'Level 4',
  3: 'Level 3',
  2: 'Level 2',
  1: 'Level 1'
}

const ReadinessLevelIndex24 = {
  3: 'Level 4',
  2: 'Level 3',
  1: 'Level 2',
  0: 'Level 1'
}

const ReadinessLevelHash = {
  1: 'In Need of Support',
  2: 'Close',
  3: 'Ready',
  4: 'Exceeds'
}

export {ReadinessLevels, ReadinessColors, ReadinessLevelFormatter, ReadinessLevels24, ReadinessLevelHash24, ReadinessLevelHash, ReadinessLevelIndex24}