<template>
  <div>
    <div ref="essaTemplate" id="essaTemplate" />

    <h5 class="text-uppercase text-dark">
      <b>Understanding Your Value-Added Growth Reports</b>
    </h5>
    <hr>
    <div class="row row-cols-md-2 gutters-sm">
      <div class="col-md-3">
        <figure>
          <img class="image-fluid" height="auto" width="240" src="/Content/ade-logo-only.png">
        </figure>
      </div>
      <div class="col-md-8">
        <p class="lead">
          <br>The purpose of the School Value-Added Growth Module is to provide secure and confidential exploration of student-level growth scores and visualizations to support local efforts to increase student learning.
        </p>
      </div>
    </div>
    <ul class="text-secondary font-size-md font-weight-light">
      <li>The reports and visualizations in this module enable educators within schools to explore patterns and trends in student growth in achievement for all students.</li>
      <li>This module supports hypothesizing, planning, and acting in response to trends and patterns discovered in the data and visualizations.</li>
      <li>Which students are included and how are they linked to teachers in this module?</li>
      <ul>
        <li>This module includes all students (mobile + non-mobile) so that educators can see the growth of all students.</li>
        <li>Grade level value-added scores are calculated by subject and grade. Educators can explore patterns and trends among grade levels and subjects relative to current and prior year achievement.</li>
        <li>Students' scores are linked to the school where the student was enrolled April 15, 2024. For students who enrolled after April 15, 2024, students are linked to the school at which the student was enrolled on the last Monday of the testing window.</li>
      </ul>
    </ul>
    <p class="text-secondary font-size-md font-weight-light">
      Your students' value-added growth scores are presented on a standard scale so they can be interpreted like a z-score[1] . Value-added growth scores at the student level are the difference between the standard score the student was expected to achieve and the actual standard score the student achieved.
    </p>

    <p class="lead">
      To understand student-level value-added growth it is more intuitive to think about a standard scale where
    </p>
    <ul class="text-secondary font-size-md font-weight-light">
      <li>0 indicates that a student met their expected growth,</li>
      <li>values greater than zero indicate a student exceeded expected their expected growth, and</li>
      <li>values less than zero indicate a student did not meet their expected growth.</li>
    </ul>
    <br>
    <img src="/Content/growth/range.png" class="img-fluid mx-auto d-block" align="center" alt>
    <br>
    <p
      class="lead"
    >
      The standard scale captures the full range of student-level value-added growth scores. The standard scale at the student level allows you to interpret the magnitude of students’ value-added growth scores relative to the expected student growth for their grade level.
    </p>
    <ul class="text-secondary font-size-md font-weight-light">
      <li>A student with a value-added growth score of 0.5000 is half a standard deviation above their expected growth.</li>
      <li>A student with a value-added growth score of -1.25 is more than one standard deviations below their expected growth.</li>
      <li>The further students are from 0 the greater the magnitude of their loss in achievement (negative values) or growth in achievement (positive values)</li>
    </ul>

    <small class="text-secondary">
      [1] The standard scale is used at the student level because the transformation for the school-level ESSA School Index score is scaled to work with school-level value-added growth scores.
    </small>
  </div>
</template>

<script>

export default {
  name: 'growth-home',
  data() {
    return { }
  },
  components: { },
  mounted() {
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.fade:not(.show) {
    opacity: 1 !important;
}
</style>